import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import LinkedIn from "../assets/linkedIn.svg"

export default function Contact() {

    const email = "tisral@yahoo.fr";
    const emailLink = `mailto:${email}`;

    return (
        <div className="container">
            <div className="row" style={{ paddingTop: "20%" }}>
                {/*<div className="col-lg-6 text-center mb-5">*/}
                {/*    <a href={emailLink}> <FontAwesomeIcon icon={faEnvelopeOpenText} size="4x" />*/}
                {/*        <br></br>*/}
                {/*        <br></br>*/}
                {/*        <h3>{email}</h3></a>*/}
                {/*</div>*/}


                <div className="col-lg-6">
                    <a style={{ textAlign: "center", display: "block" }} href="https://www.linkedin.com/in/israeltshitenge/">
                        <img style={{ width: "5em", height: "5em" }} src={LinkedIn} alt="linkedIn" ></img>
                    </a>
                </div>
            </div>

            <br></br>
        </div>

    )
}
