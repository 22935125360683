import React, {useState} from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

export default function NavBar() {

    const [collapsed, setCollapsed] = useState(true);
    return (
        <Navbar expanded={collapsed} bg="light" expand="lg">
            <Link to="/resume"><Navbar.Brand >Israel Tshitenge</Navbar.Brand></Link>
            <Navbar.Toggle onClick={() => setCollapsed(!collapsed)} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse   id="basic-navbar-nav">

                <Nav className="mr-auto">
                    <NavLink className="nav-link" to="/resume">Resume</NavLink>
                    <NavLink className="nav-link" to="/projects">  Projects</NavLink>
                    <NavLink className="nav-link" to="contact"> Contact</NavLink>
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    )
}
