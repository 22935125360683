import React from 'react'
import reactLogo from "../assets/react.svg"
import playStoreLogo from "../assets/google-play-badge.png"
import iosLogo from "../assets/app-store-black.svg";

export default function Projects() {
    return (
        <div className="container">
            <br /><br />

            <h1 id="lingala">Lingala</h1>

            <p className="lead text-muted">
                Developed an iOS, an Android, and a React web app that translates words from English and French to Lingala and vice versa. It features an option to see the type of words and a list of synonyms for a particular word in Lingala. In addition, the app offers a list of common phrases in Lingala
                and their English and French translations and vice versa. All verbs are listed in a specific category to better navigate to the selected verb conjugation table, which includes a variety of tenses. Also, the user can save words and review them later.

                <br />
                The app includes:
            </p>

            <ul className="lead text-muted">
                <li>A dictionary</li>
                <li>A list of common phrases</li>
                <li>A list of verbs</li>
                <li>Conjugation table for every verb</li>
                <li>Saving words for future review</li>
                <li>Audio support for words and common phrases</li>
            </ul>

            <h3>Technologies</h3>

            <ul>
                <li><h6>Android</h6>
                    <ul className="list-group list-group-horizontal-sm text-muted">
                        <li className="list-group-item">Android Development (Java) &nbsp; <small style={{ color: "orangeRed" }}>until version 2.3.6</small></li>
                        <li className="list-group-item">Flutter  &nbsp; <small style={{ color: "blue" }}>from version 2.3.7</small></li>
                        <li className="list-group-item">Firebase</li>
                    </ul>
                </li>
                <br></br>
                <li><h6>iOS</h6>
                    <ul className="list-group list-group-horizontal-sm text-muted">
                        <li className="list-group-item">Flutter</li>
                        <li className="list-group-item">Firebase</li>
                    </ul>
                </li>
                <br></br>
                <li> <h6>Web</h6>
                    <ul className="list-group list-group-horizontal-sm text-muted">
                        <li className="list-group-item">React</li>
                        <li className="list-group-item">JavaScript</li>
                        <li className="list-group-item">HTML</li>
                        <li className="list-group-item">CSS</li>
                        <li className="list-group-item">Bootstrap</li>
                        <li className="list-group-item">Firebase</li>
                    </ul>
                </li>

            </ul>

            <br></br>
            <br></br>

            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <video style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="360" height="560" scrolling="no" allowFullScreen controls>
                        <source src="https://storage.googleapis.com/www.israeltshitenge.com/Resume/Lingala.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <br /><br />

                    <a style={{ textAlign: "center", display: "block" }} href="https://play.google.com/store/apps/details?id=com.tsgtechnologies.lingala"> <img style={{ width: "15em", height: "5.8em" }} src={playStoreLogo} alt="google play" /></a>
                    <a style={{ textAlign: "center", display: "block", marginTop: 10 }} href="https://apps.apple.com/us/app/lingala/id1569540134?itsct=apps_box_badge&amp;itscg=30200">
                        <img style={{ width: "210px", height: "auto" }} src={iosLogo} alt="Download on the App Store" />
                    </a>


                </div>

                <div className="col-lg-6 col-sm-12 my-4">
                    <a style={{ textAlign: "center", display: "block" }} href="https://www.yekola-lingala.com">
                        <img style={{ width: "15em", height: "15em" }} src={reactLogo} alt="react logo" />
                        <h4>Link to the React Web App</h4></a
                    >
                </div>
            </div>


            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="spotlight">Spotlight</h1>
            <p className="lead text-muted">
                Group Project: Senior Design<br></br>
                SpotLight is an iOS and Android compatible mobile application that is used to help users know what plants are good to be planted in their given location. SpotLight will use the user’s location to apply the sun path on the app. Then, the user will capture images of the skyline so that the app can generate the sunshade/sun intensity graph. The goal of the SpotLight is to output a list of recommended plants to the user and save the user’s time from having to do their own research.
                <br></br>
                Features (individual features implemented in <span style={{ color: "royalBlue" }}>blue</span>):
                <br></br>
                <br></br>
                <ul>
                    <li>Sun Path</li>
                    <li style={{ color: "royalBlue" }}>Sun Location</li>
                    <li style={{ color: "royalBlue" }}>Sky Detection</li>
                    <li style={{ color: "royalBlue" }}>Sun Shade Graph</li>
                </ul>
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">React Native</li>
                <li className="list-group-item">Expo</li>
                <li className="list-group-item">JavaScript</li>
                <li className="list-group-item">Firebase</li>
                <li className="list-group-item">Google Cloud Platform (GCP)</li>
            </ul>

            <br></br>
            {/* <h5><a href="https://github.com/tisral/CSE-4316">GitHub Link</a></h5> */}

            <h5><a href="https://blog.uta.edu/cseseniordesign/2021/04/12/spotlight/">Project Link</a></h5>



            <br /><br />
            <br /><br />

            <video style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="360" height="560" scrolling="no" allowFullScreen controls>
                <source src="https://firebasestorage.googleapis.com/v0/b/spotlight-87515.appspot.com/o/Spotlight%20Demo.mp4?alt=media&token=bdebd527-d7d5-4683-8e4d-2b9a0569b429" type="video/mp4" />
                Your browser does not support the video tag.
            </video>


            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="vidly">Vidly</h1>
            <p className="lead text-muted">
                Developed a React web app and a Node.JS back-end service for renting out movies. This app features authentication, registration, and management of movies.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">React</li>
                <li className="list-group-item">JavaScript</li>
                <li className="list-group-item">HTML</li>
                <li className="list-group-item">CSS</li>
                <li className="list-group-item">Firebase Hosting</li>
            </ul>

            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">Node JS</li>
                <li className="list-group-item">Express JS</li>
                <li className="list-group-item">MongoDB</li>
                <li className="list-group-item">Firebase Functions</li>
            </ul>

            <br></br>
            <br></br>

            <a style={{ textAlign: "center", display: "block" }} href="https://vidly-ba598.web.app/" >
                <img style={{ width: "15em", height: "15em" }} src={reactLogo} alt="react logo" />
                <h4>Link to the React Web App</h4>
            </a>

            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="serviceUp">Service Up</h1>
            <p className="lead text-muted">
                Developed an android app that provides a platform for users to easily find and provide services which they need or are good at to benefit one another. This platform helps the users of this application to seamlessly navigate their needs using neat features such as an option to message or call
                the service provider or the customer, a dashboard from where a customer can search a service, an appointment scheduler.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">Android Development</li>
                <li className="list-group-item">Java</li>
                <li className="list-group-item">Firebase</li>
            </ul>

            <br></br>
            <h5><a href="https://github.com/tisral/ServiceUp">GitHub Link</a></h5>

            <br></br>
            <br></br>

            <video style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="360" height="560" scrolling="no" allowFullScreen controls>
                <source src="https://storage.googleapis.com/www.israeltshitenge.com/Resume/ServiceUp.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="demiCare">Demi-Care</h1>
            <p className="lead text-muted">
                Group Project: <br></br>
                React website providing resources to Chinese and Koreans suffering from dementia in the Dallas - FortWorth area.
                <br></br>
                Features (individual features implemented in <span style={{ color: "royalBlue" }}>blue</span>):
                <br></br>
                <br></br>
                <ul>
                    <li>Embedded map of treatment centers</li>
                    <li style={{ color: "royalBlue" }}>Dementia Information</li>
                    <li>Appointment Scheduler</li>
                    <li>Outreach</li>
                    <li style={{ color: "royalBlue" }}>Translation (Korean, Chinese)</li>
                    <li>Contact us</li>
                    <li style={{ color: "royalBlue" }}>Admin Panel</li>
                </ul>
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">React</li>
                <li className="list-group-item">JavaScript</li>
                <li className="list-group-item">HTML</li>
                <li className="list-group-item">CSS</li>
                <li className="list-group-item">BaseUI</li>
                <li className="list-group-item">Firebase</li>
            </ul>

            <br></br>
            <h5><a href="https://github.com/tisral/demicare">GitHub Link</a></h5>



            <br></br>
            <br></br>


            <a style={{ textAlign: "center", display: "block" }} href="https://demi-care.web.app/" >
                <img style={{ width: "15em", height: "15em" }} src={reactLogo} alt="react logo" />
                <h4>Link to the React Web App</h4>
            </a>

            <br /><br />
            <hr></hr>
            <br /><br />



            <h1 id="myTuition">MyTuition, City Technlogy Hackaton January 29th, 2021 -- Febrary 1st, 2021</h1>
            <p className="lead text-muted">

                Developed an online bank for saving. This is a React web app that allows users to save money for their college education. The app features authentication and registration, and money management operations such as deposits, withdraws, and linking a debit card to their account.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">React</li>
                <li className="list-group-item">JavaScript</li>
                <li className="list-group-item">HTML</li>
                <li className="list-group-item">CSS</li>
                <li className="list-group-item">Firebase</li>
            </ul>

            <br></br>
            <h5><a href="https://github.com/tisral/my-tuition">GitHub Link</a></h5>



            <br></br>
            <br></br>


            <a style={{ textAlign: "center", display: "block" }} href="https://mytuition-82223.web.app/" >
                <img style={{ width: "15em", height: "15em" }} src={reactLogo} alt="react logo" />
                <h4>Link to the React Web App</h4>
            </a>

            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="trickOrTreaterRace">TrickOrTreater Race</h1>
            <p className="lead text-muted">
                C++ project implementing LinkedList, Stack, Queue, and Threads. This program displays a list of trick-or-treaters racing while giving out candies and toothbrushes.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">C++</li>

            </ul>

            <br></br>
            <h5><a href="https://github.com/tisral/TrickOrTreater-Race">GitHub Link</a></h5>


            <br></br>
            <br></br>

            <video style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="360" height="264" scrolling="no" allowFullScreen controls>
                <source src="https://storage.googleapis.com/www.israeltshitenge.com/Resume/TrickOrTreaterRace.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>


            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="fat32FileSystem">FAT32 File System</h1>
            <p className="lead text-muted">
                C program that reads a FAT32 disk image and allows users to interact with that file system. This application supports various commands to open and close a FAT32 image, print out information about the file system, print the attributes and starting cluster number of the file or the directory,
                retrieve the file from the FAT32 image and place it in the current working directory, change the current working directory to the given directory, list the directory contents, and read from the given file at the position, in bytes, specified by the position parameter and output the number of
                bytes specified.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">C</li>
            </ul>

            <br></br>
            <h5><a href="https://github.com/tisral/FAT32-File-System">GitHub Link</a></h5>



            <br></br>
            <br></br>

            <video style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="360" height="264" scrolling="no" allowFullScreen controls>
                <source src="https://storage.googleapis.com/www.israeltshitenge.com/Resume/FAT32%20File%20System.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <br /><br />
            <hr></hr>
            <br /><br />

            <h1 id="snackMachine">Snack Machine</h1>
            <p className="lead text-muted">
                Developed a snack machine user interface using Object Oriented C++. This program allows client users to pick a machine from a list of various snack machines and buy items from that speci c machine. In addition, it creates options for administrator users to add new snack machines and do the
                following operations on each individual snack machine: change the name of the machine, set and update the price of items, add change, restock the inventory, and display the machine info.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">C++</li>
            </ul>

            <br></br>
            <hr></hr>
            <br></br>

            <h1 id="movieTheaterTicketMachine">Movie Theater Ticket Machine</h1>
            <p className="lead text-muted">
                C project implementing dynamic allocation using linked list, stack, queue, and binary search tree. This program registers customers'names, prompts to sell tickets to a customer, see who is in line, see the seat map for a given theater, and print tickets for the sold seats. It prompts the
                user to enter a zipcode, nds the corresponding theater, shows the theater's seat map, and lets the user buy tickets depending on the seats that are available.
            </p>

            <h3>Technologies</h3>
            <ul className="list-group list-group-horizontal-sm text-muted">
                <li className="list-group-item">C</li>
            </ul>
            <br></br>
            <br></br>
        </div>
    )
}
