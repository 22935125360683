import './App.css';
import NavBar from './screens/NavBar';
import Resume from './screens/Resume';
import Contact from './screens/Contact';
import Projects from './screens/Projects';

import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from './screens/NotFound';

function App() {
  return (
    <div className="app">
      <NavBar />
      <div >
        <Switch>
          <Route exact path="/resume" component={Resume} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/contact" component={Contact} />
          <Route path="/not-found" component={NotFound} />
          <Redirect exact from="/" to="/resume" component={Resume} />
          <Redirect exact from="/Resume/israel_Tshitenge.html" to="/resume" />
          <Redirect exact from="/Resume/projects.html" to="/projects" />
          <Redirect exact from="/Resume/contact.html" to="/contact" />
          <Redirect to="/not-found" />
        </Switch>
      </div>

    </div>
  );
}

export default App;
