import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileDownload} from '@fortawesome/free-solid-svg-icons';

export default function Resume() {
    return (
        <div>
            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    {/* <h1 className="display-4">Seeking a Full-Time Position in Software Development and Testing</h1> */}
                    <h1 className="display-4" style={{textAlign: 'center'}}>Full-Stack Developer</h1>
                    <br></br>

                    <a style={{textAlign: "center", display: "block"}}
                       href="https://1drv.ms/b/s!AkPhmxzmD_dMj-kwlp2nxkxGDzW0jw?e=x53XnK">
                        <FontAwesomeIcon icon={faFileDownload} style size="4x"/>
                        <br></br>
                        <br></br>
                        <h5>Israel's Resume</h5>
                    </a>
                </div>
            </div>

            <div className="container">
                <h1>Education</h1>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <p className="lead">University of Texas at Arlington</p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <p className="lead" style={{textAlign: "right"}}>May 2021</p>
                    </div>
                </div>

                <ul className="lead text-muted">
                    <li>B.S in Software Engineering</li>
                    <li>Minor: Mathematics</li>
                    <li>GPA: 3.18</li>
                </ul>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <p className="lead">Brookhaven College</p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <p className="lead" style={{textAlign: "right"}}>July 2018</p>
                    </div>
                </div>

                <ul className="lead text-muted">
                    <li>Associate of Science</li>
                    <li>GPA: 3.68</li>
                </ul>

                <h1>Skills & Abilities</h1>
                <p className="lead text-muted"><b>Programming: </b> C, C++, C#, Java, Python, Dart, .Net, Spring
                    Framework, Hadoop, HiveQL, Microsoft SQL Server, MySQL, IBM DB2, HTML,
                    CSS, JavaScript, Angular, React, React Native, Node.js, MongoDB, Express.js, Windows Service, gRPC,
                    SignalR, Android Development (Java), Flutter, Redis, MATLAB, Linux/Unix, Firebase, Docker, AWS, GCP,
                    Git, Azure DevOps Pipelines, SonarType, Checkmarkx, Splunk, ServiceNow, Jira, Confluence,
                    Bitbucket, Visual Studio, IntelliJ IDEA, Linux/UNIX, Windows
                    <br/>
                    <br/>
                    <b>Languages (fluent in written and spoken for all): </b> English, French, Lingala</p>

                <h1>Relevant Coursework</h1>
                <p className="lead text-muted">Algorithms & Data Structures, Object Oriented Programming, Computer
                    Organization & Assembly Language, Discrete Structures, Software Engineering, Programming Languages,
                    Operation Systems, Database</p>

                <h1>Experience</h1>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <h5>
                            <b>Software Engineer, Visa Inc.</b>
                        </h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <h5 style={{textAlign: "right"}}>
                            <b>06/2021 - Present</b>
                        </h5>
                    </div>
                </div>

                <ul className="lead text-muted">

                    <li>Work as a Full-Stack Developer and DevOps with strong problem-solving skills.</li>
                    <li>Develop and maintain large-scale applications with real-time processing and high availability.
                    </li>
                    <li>Experience in web, API, console, and Windows service application development and deployment.
                    </li>
                    <li>Remediate application and server security vulnerabilities and SSDLC findings.</li>
                    <li>Lead the app development of Visa Emergency Service for Issuers and Cardholders, providing
                        critical support.
                    </li>
                    <li>Specialize in parsing and capturing ISO 8583 transaction messages for secure and efficient
                        processing.
                    </li>

                </ul>


                <br></br>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <h5>
                            <b>Math Tutor & Lab Assistant, University of Texas at Arlington </b>
                        </h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <h5 style={{textAlign: "right"}}>
                            <b>09/2018 - 05/2021</b>
                        </h5>
                    </div>
                </div>

                <p className="lead mx-4">Math Learning Resource Center</p>
                <ul className="lead text-muted">
                    <li>Work with students individually as well as in small group in contemporary math, college algebra,
                        trigonometry, pre-calculus, calculus, and linear algebra
                    </li>
                    <li>Attend lectures and assist students during in-className activities</li>
                    <li>Proctor Math exams</li>
                </ul>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <h5>
                            <b>Software Engineer Intern, GoGreatMoods.com </b>
                        </h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <h5 style={{textAlign: "right"}}>
                            <b>07/2020 - 08/2020</b>
                        </h5>
                    </div>
                </div>

                <ul className="lead text-muted">
                    <li>Worked as a Full-Stack Developer where upgraded the national fundraising website</li>
                    <li>Designed and developed new features for project releases</li>
                    <li>Worked within the agile team to provide technical input to backlog management</li>
                </ul>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <h5><b>Math Tutor, Brookhaven College</b></h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <h5 style={{textAlign: "right"}}>
                            <b>06/2017 - 07/2018</b>
                        </h5>
                    </div>
                </div>

                <p className="lead mx-4">STEM Resource Center</p>

                <ul className="lead text-muted">
                    <li>Assisted students in developmental math, college algebra, trigonometry, pre-calculus, and
                        calculus
                    </li>
                    <li>Helped students improve their math skills by showing efficient techniques to solve problems and
                        adapted explanations based on student comprehension
                    </li>
                </ul>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <h5><b>Student Assistant, Brookhaven College</b></h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <h5 style={{textAlign: "right"}}>
                            <b>01/2017 - 07/2018</b>
                        </h5>
                    </div>
                </div>

                <p className="lead mx-4">Biology Preparatory Lab</p>

                <ul className="lead text-muted">
                    <li>Prepared lab equipment and make solutions for various biology courses</li>
                    <li>Restocked glassware, put pieces of models back together and return equipment to their proper
                        locations
                    </li>
                    <li>Assisted in the maintenance of laboratory equipment and prep-room of Biology labs</li>
                    <li>Took inventory of lab supplies at the end of every semester</li>
                </ul>

                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-6">
                        <h5><b>Intern, Central Bank of the Congo</b></h5>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <h5 style={{textAlign: "right"}}>
                            <b>05/2015 - 06/2015</b>
                        </h5>
                    </div>
                </div>

                <p className="lead mx-4">Office of Banking And Market Operations</p>

                <ul className="lead text-muted">
                    <li>Received, stamped, sorted, and dispatched documents received from commercial banks and privates
                        companies
                    </li>
                    <li>Made copies and scans of documents</li>
                </ul>

                <h1>Honors & Awards</h1>
                <ul className="lead text-muted">
                    <li>National Society of Black Engineers</li>
                    <li>University of Texas at Arlington Transfer Scholarship</li>
                    <li>Phi Theta Kappa Scholarship</li>
                    <li>DCCCD STEM Institute Scholarship</li>
                    <li>The National Society of Collegiate Scholars Honor Society</li>
                </ul>
            </div>
        </div>
    )
}
